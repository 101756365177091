import React from "react";
import "./MembershipSuccess.css";
// import { Link } from "react-router-dom";
import GradientLine from "../../components/GradientLine/GradientLine";
import Footer from "../../components/Footer/Footer";

export const MembershipSuccess = () => {
  return (
    <section id="membership-success">
      <div className="membership-success-showcase">
        <h4>Membership</h4>
        <p className="para-one">Take the First Step, Find Your Soul Mate</p>
        <GradientLine />
        <p className="para-two">
          Join a community of countless matched couples today!
        </p>
      </div>

      <div className="membership-success-details">
        <h5>Application Successfully Submitted</h5>
        <p className="para-details-one">
          Thank You for Choosing Jeeta Matchmaking
        </p>
        <p className="para-details-two">
          If you’re application was successful we will reach out to you via
          you’re preferred contact method within 1-2 days to move forward with
          your application.
        </p>
        <p className="para-details-three">
          Until then you are free to review and alter your application in{" "}
          <br/>
          <a href="/amendMembership">Your Profile</a>
        </p>
      </div>

      <Footer />
    </section>
  );
};
