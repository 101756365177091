import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./css/index.css";
import "./css/media.css";
import NavbarWithConditionalRendering from "./components/NavigationBar/NavbarWithConditionalRendering";
import { LandingPage } from "./screens/LandingPage";
import { AboutUs } from "./screens/AboutUs";
import { LogIn } from "./screens/LogIn";
import { SignUp } from "./screens/SignUp";
import { PrivacyPolicy } from "./screens/PrivacyPolicy/PrivacyPolicy";
import { MembershipSuccess } from "./screens/MembershipSuccess/MembershipSuccess";
import { Membership } from "./screens/Membership";
import { AuthProvider } from "./AuthContext";
import PrivateRoute from "./PrivateRoute"; // Import the PrivateRoute component
import { AmendMembership } from "./screens/AmendMembership/AmendMembership";
import { UpdatePassword } from "./screens/UpdatePassword";
import { ResetPasswordRequest } from "./screens/ResetPasswordRequest";

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <main className="container">
          <NavbarWithConditionalRendering />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route
              path="/resetPasswordRequest"
              element={<ResetPasswordRequest />}
            />
            <Route
              path="/reset-password"
              element={
                <PrivateRoute>
                  <UpdatePassword />
                </PrivateRoute>
              }
            />

            <Route
              path="/membershipSuccess"
              element={
                <PrivateRoute>
                  <MembershipSuccess />
                </PrivateRoute>
              }
            />
            <Route
              path="/membership"
              element={
                <PrivateRoute>
                  <Membership />
                </PrivateRoute>
              }
            />
            <Route
              path="/amendMembership"
              element={
                <PrivateRoute>
                  <AmendMembership />
                </PrivateRoute>
              }
            />
          </Routes>
        </main>
      </AuthProvider>
    </Router>
  );
};

export default App;
