import React from "react";
import "./PrivacyPolicy.css";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Footer from "../../components/Footer/Footer";
import GradientLine from "../../components/GradientLine/GradientLine";

export const PrivacyPolicy = () => {
  return (
    <section id="privacy-policyPage">
      <div className="privacy-policyPage_showcase">
        <h4>privacy policy</h4>
        <p className="para-one">The steps we take to protect your data</p>
        <GradientLine />
        <p className="para-two">
          We take our commitment to our clients seriously. This includes how we
          use your information.
        </p>

        <div className="showcase-chevron">
          <span>Discover our Privacy Policy</span>
          <p>
            <HiOutlineChevronDoubleDown />
          </p>
        </div>
      </div>

      <div className="privacy-policyPage_texts">
        <div className="privacy-policyPage_texts-background">
          <h5>background</h5>
          <p>
            This privacy policy represents the commitment of Jeeta Matchmaking,
            hereinafter referred to as "We," "Our," or "Us," regarding the
            protection and confidentiality of personal information collected
            from our clients, hereinafter referred to as "Users," "Clients," or
            "You." This policy sets out the procedures and practices regarding
            the collection, use, retention, and disclosure of personal data
            under our control.
          </p>
        </div>

        <div className="privacy-policyPage_texts-policies">
          <article className="privacy-policyPage_texts-policies-article">
            <h3>1. Information Collection and Usage</h3>

            <div className="privacy-policyPage_texts-policies-article_steps">
              <p className="privacy-policyPage_texts-policies-article_step">
                1.1 Personal Information: We collect certain personal
                information from Clients during the registration process or when
                utilizing our matchmaking services. The information we collect
                may include but is not limited to:
              </p>
              <div className="privacy-policyPage_texts-policies-article_step-details">
                <p>- Full name</p>
                <p>
                  - Contact details (email address, phone number, address, etc.)
                </p>
                <p>- Date of birth</p>
                <p>- Gender identity</p>
                <p>- Occupation and education history</p>
                <p>- Interests, hobbies, and lifestyle preferences</p>
                <p>- Preferences for potential matches</p>
                <p>- Photographs and videos (optional)</p>
                <p>- Communication records with our staff</p>
                <p>- Financial information (for billing purposes only)</p>
              </div>
              <p className="privacy-policyPage_texts-policies-article_step">
                1.2 Consent: By voluntarily providing us with your personal
                information, you consent to its collection, use, and disclosure
                in accordance with this privacy policy. You have the right to
                withdraw your consent at any time by closing your account or
                contacting us directly.
              </p>
            </div>
          </article>

          <article className="privacy-policyPage_texts-policies-article">
            <h3>2. Use and Retention of Information</h3>

            <div className="privacy-policyPage_texts-policies-article_steps">
              <p className="privacy-policyPage_texts-policies-article_step">
                2.1 Matchmaking Services: We use the personal information
                provided by Clients solely for the purpose of providing
                matchmaking and related services. We utilize this information to
                determine compatible matches, personalize our services, improve
                user experiences, and communicate with Users. We retain the
                information until the Client or our own company closes the
                account, after which the user data is securely erased.
              </p>
              <p className="privacy-policyPage_texts-policies-article_step">
                2.2 Communication: We may use Clients' contact information to
                send updates, notifications, marketing materials, or promotional
                offers related to our services, subject to Client preferences.
                You can opt-out of these communications at any time by
                contacting us or using the provided unsubscribe link.
              </p>
              <p className="privacy-policyPage_texts-policies-article_step">
                2.3 Third-Party Sharing: We do not disclose personal information
                to any third party unless required by law or as necessary to
                provide our matchmaking services. We do not sell or rent
                personal information to any party for marketing purposes.
              </p>
            </div>
          </article>

          <article className="privacy-policyPage_texts-policies-article">
            <h3>3. Storage and Security</h3>

            <div className="privacy-policyPage_texts-policies-article_steps">
              <p className="privacy-policyPage_texts-policies-article_step">
                3.1 Data Security: We implement reasonable security measures to
                protect personal information from unauthorized access, misuse,
                loss, disclosure, alteration, or destruction. However, no data
                transmission can be entirely secure. Thus, while we strive to
                protect personal information, we cannot guarantee its absolute
                security.
              </p>
              <p className="privacy-policyPage_texts-policies-article_step">
                3.2 Overseas Transfers: As part of our matchmaking services, we
                may transfer personal information to our servers or authorized
                third parties who may process the data on our behalf. These
                transfers may involve sending information overseas, including to
                countries with laws that may offer different levels of data
                protection. By providing us with personal information, you
                consent to such transfers.
              </p>
            </div>
          </article>

          <article className="privacy-policyPage_texts-policies-article">
            <h3>4. User Rights and Account Closure</h3>

            <div className="privacy-policyPage_texts-policies-article_steps">
              <p className="privacy-policyPage_texts-policies-article_step">
                4.1 Access and Rectification: You have the right to access,
                correct, update, or delete your personal information by logging
                into your account or contacting us directly. We will make
                reasonable efforts to fulfill such requests, subject to any
                legal obligations or technical constraints.
              </p>
              <p className="privacy-policyPage_texts-policies-article_step">
                4.2 Account Closure: If you choose to close your account, we
                will securely delete your personal information within a
                reasonable period unless otherwise required by applicable law.
              </p>
            </div>
          </article>

          <article className="privacy-policyPage_texts-policies-article">
            <h3>5. Policy Updates</h3>

            <div className="privacy-policyPage_texts-policies-article_steps">
              <p className="privacy-policyPage_texts-policies-article_step">
                5.1 Changes to Policy: We reserve the right to update this
                privacy policy from time to time. Any modifications will be
                effective when posted on our website. You are encouraged to
                review this policy periodically for any changes.
              </p>
              <p className="privacy-policyPage_texts-policies-article_step">
                5.2 Contact Us: If you have any questions, concerns, or
                suggestions regarding this privacy policy, please contact us at
                insert contact information.
              </p>

              <p className="privacy-policyPage_texts-policies-article_step">
                5.3 By creating an account or using our services, you
                acknowledge that you have read, understood, and agreed to be
                bound by this privacy policy.
              </p>
            </div>
          </article>
        </div>
      </div>

      <Footer />
    </section>
  );
};
