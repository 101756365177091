import React, { useState } from "react";
import "./ResetPasswordRequest.css";
import { Link } from "react-router-dom";
import SubmitSuccessful from "../../components/SubmitSuccessful/SubmitSuccessful";

export const ResetPasswordRequest = () => {
  const [email, setEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url =
        "https://jeeta-backend.fly.dev/api/user/resetPassword/request";
      const data = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (data.ok) {
        setIsSubmit(true);
        return;
      }
    } catch (error) {
      setSuccessMessage(
        "Failed to send password reset request. Please try again."
      );
      alert(successMessage);
      console.log(error);
    }
  };

  return (
    <section id="requestPassword">
      {isSubmit ? <SubmitSuccessful /> : null}
      <div className="requestPassword-content">
        <div className="requestPassword-content-text">
          <div className="requestPassword-content-header">
            <h2>forgot your password?</h2>
            <p>
              Enter the email address you used when you joined and we’ll send
              you instructions to recover your password.
            </p>
          </div>
          <form onSubmit={handleSubmit} className="requestPassword-content-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter your email"
              required
            />
            <button type="submit">Submit</button>
          </form>

          <div className="requestPassword-content-register">
            <span>don't have an account?</span>
            <Link to="/signup">
              <p>sign up</p>
            </Link>
          </div>

          <div className="requestPassword-content-redirect">
            <span>Back To</span>
            <Link to="/">
              <p>homepage</p>
            </Link>
          </div>
        </div>

        <div className="requestPassword-content-image"></div>
      </div>
    </section>
  );
};

export default ResetPasswordRequest;
