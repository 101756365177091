import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./UpdatePassword.css";

export const UpdatePassword = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const { token } = useParams();
  if (!token) {
    return <div className="token-header">Invalid or missing token</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      alert("Passwords do not match");
      return
    } else {
      const response = await fetch(
        "https://jeeta-backend.fly.dev/api/user/resetPassword/update",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token,
            email,
            newPassword: password,
          }),
        }
      );

      if (response.ok) {
        alert("Password updated successfully");
        navigate("/login"); // Redirect to the login page after successful update
      } else {
        const data = await response.json();
        alert(data.message);
      }
    }
  };

  return (
    <section id="updatePassword">
      <div className="updatePassword-content">
        <div className="updatePassword-content-text">
          <div className="updatePassword-content-text_header">
            <h2>Update your password</h2>
            <p>Create new Password for your account</p>
          </div>

          <form onSubmit={handleSubmit} className="updatePassword-content-form">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="enter your email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="enter new password"
              required
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="confirm new password"
              required
            />
            <button type="submit">Submit</button>
          </form>

          <div className="updatePassword-content-register">
            <span>don't have an account?</span>
            <Link to="/signup">
              <p>sign up</p>
            </Link>
          </div>

          <div className="updatePassword-content-redirect">
            <span>Back To</span>
            <Link to="/">
              <p>homepage</p>
            </Link>
          </div>
        </div>

        <div className="updatePassword-content-image"></div>
      </div>
    </section>
  );
};
