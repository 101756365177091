import React, { useState } from "react";
import "./AmendMembership.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

export const AmendMembership = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    // Create an object to hold the form values
    const formValues = {};

    // Loop through the formData and populate the formValues object
    for (const [name, value] of formData.entries()) {
        formValues[name] = value;
    }

    console.log(formValues);

    const userId = localStorage.getItem("userId");
    const jwt = localStorage.getItem("jwtToken");

    if (!jwt) {
      console.error("JWT not found in localStorage");
      return;
    }

    const url = `https://jeeta-backend.fly.dev/api/user/${userId}/amendApplication`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: jwt,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      if (response.ok) {
        console.log("Application submission success");

        const data = await response.json();
        const { s3Link } = data;

        if (formData.get("photo")) {
          try {
            const imageBlob = formData.get("photo");
            const imageResponse = await fetch(s3Link, {
              method: "PUT",
              headers: {
                Authorization: jwt,
                "Content-Type": "image/jpeg", // Adjust content type if needed
              },
              body: new Blob(imageBlob),
            });

            if (imageResponse.ok) {
              console.log("Image upload success");
              navigate("/membershipSuccess");
            } else {
              console.error("Image upload failed");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        } else {
          navigate("/membershipSuccess");
        }
      } else {
        console.error("Application update submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };


  return (
    <section id="amendMembership">
      <div className="amendMembership-showcase">
        <div className="amendMembership-details">
          <h5>making amendments</h5>
          <p className="para-details-one">
            Here You can make any desired changes to the details submitted in
            your application form.
          </p>
          <p className="para-details-one">
            Remember to select the “confirm changes” option at the bottom of the
            application form so the amendments are final.
          </p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="membership-form">
        <h4 className="form-header">Please fill the form below</h4>
        <div className="input-container-box-1">
          <div className="input-box">
            <label htmlFor="mobileNumber">enter mobile number</label>
            <input type="number" name="mobileNumber" required />
          </div>
          <div className="input-box">
            <label htmlFor="address">enter address</label>
            <input type="text" name="address" required />
          </div>
        </div>
        <div className="input-container-box-1">
          <div className="input-box">
            <label htmlFor="height">enter height (cm)</label>
            <input type="number" name="height" required />
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="gender">gender</label>
          <div className="input-box">
            <input type="radio" name="gender" value="male" required />
            <span>male</span>
          </div>
          <div className="input-box">
            <input type="radio" name="gender" value="female" required />
            <span>female</span>
          </div>
        </div>
        <div className="input-container-box-3">
          <label htmlFor="ethnicity">ethnicity</label>
          <input type="text" name="ethnicity" id="ethnicity" />
        </div>
        <div className="input-container-box-2">
          <label htmlFor="practicing">do you practice religion?</label>
          <div className="input-box">
            <input type="radio" name="practicing" value="yes" required />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input type="radio" name="practicing" value="no" required />
            <span>no</span>
          </div>
        </div>
        <div className="input-container-box-3">
          <label htmlFor="religion">what is your religion?</label>
          <input type="text" name="religion" id="religion" />
        </div>
        <div className="input-container-box-2">
          <label htmlFor="marital_status">marital status</label>
          <div className="input-box">
            <input
              type="radio"
              name="marital_status"
              value="married"
              required
            />
            <span>married</span>
          </div>
          <div className="input-box">
            <input type="radio" name="marital_status" value="single" required />
            <span>single</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="marital_status"
              value="divorced"
              required
            />
            <span>divorced</span>
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="hasChildren">do you have children?</label>
          <div className="input-box">
            <input type="radio" name="hasChildren" value="yes" required />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input type="radio" name="hasChildren" value="no" required />
            <span>no</span>
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="wantChildren">do you want to have children?</label>
          <div className="input-box">
            <input type="radio" name="wantChildren" value="yes" required />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input type="radio" name="wantChildren" value="no" required />
            <span>no</span>
          </div>
        </div>
        <div className="input-container-box-3">
          <label htmlFor="highestQualification">
            Please enter the highest level of education obtained
          </label>
          <input
            type="text"
            name="highestQualification"
            id="highestQualification"
          />
        </div>
        <div className="input-container-box-3">
          <label htmlFor="universityDegreeSubject">
            please enter your course-major studied
          </label>
          <input
            type="text"
            name="universityDegreeSubject"
            id="universityDegreeSubject"
          />
        </div>
        <div className="input-container-box-3">
          <label htmlFor="profession">
            Please enter your occupation/profession
          </label>
          <input type="text" name="profession" id="profession" />
        </div>
        <div className="input-container-box-3">
          <label htmlFor="photo">
            Upload a picture of yourself (current selfie)
          </label>
          <input
            type="file"
            name="photo"
            accept="image/jpeg, image/png"
            required
          />
        </div>
        <div className="input-container-box-3">
          <button className="form-btn" type="submit">
            Confirm Changes
          </button>
        </div>
      </form>
      <Footer />
    </section>
  );
};
