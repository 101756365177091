import React from "react";
import { Link } from "react-router-dom";

import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-center">
        <article className="footer-center-box logo">
          <a href="/" className="footer-center-box-header">jeeta matchmaking</a>
          <p>Professional Matchmaking Service for South Asian Singles.</p>
        </article>
        <article className="footer-center-box">
          <h3 className="footer-center-box-header">company</h3>

          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/aboutUs">About</Link>
            </li>
            <li>
              <Link to="/membership">Membership</Link>
            </li>
            <li>
              <Link to="/login">Sign in</Link>
            </li>
          </ul>
        </article>
        <article className="footer-center-box">
          <h3 className="footer-center-box-header">privacy policy</h3>

          <ul>
            <li>
              <Link to="/privacyPolicy">Privacy Policy</Link>
            </li>
            <li>
              <Link>FAQs</Link>
            </li>
          </ul>
        </article>
        <article className="footer-center-box">
          <h3 className="footer-center-box-header">contact us</h3>

          <ul>
            <li>
              <Link>info@jeetamatch.com</Link>
            </li>
            <li>
              <p>1-800-200-300</p>
            </li>
            <li>
              <p>9103 Queen Street, London, NW15 2OF</p>
            </li>
          </ul>
        </article>
      </div>
    </footer>
  );
};

export default Footer;
