import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SignUp.css";
import { AuthContext } from "../../AuthContext";
import { GoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
// OLD STYLE LINK
// import "./style.css";

export const SignUp = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const { setIsAuthenticated, isAuthenticated } = useContext(AuthContext);

  const responseMessage = async (response) => {
    const jwt = response.credential;
    const googleAuthResponse = await fetch(
      "https://jeeta-backend.fly.dev/api/user/google/sign_up",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: jwt }),
      }
    );

    const resp = await googleAuthResponse.json();

    let token = resp.jwt;
    let userId = resp.userId;

    if (googleAuthResponse.ok) {
      localStorage.setItem("jwtToken", token);
      localStorage.setItem("userId", userId);
      setIsAuthenticated(true);
      navigate("/membership");
    } else {
      console.error("Google sign in failed!");
    }
  };

  const errorMessage = (error) => {
    console.log(error);
  };

  const handleSignUp = async () => {
    if (
      username === "" ||
      email === "" ||
      password === "" ||
      username.length === 0 ||
      email.length === 0 ||
      password.length === 0
    )
    {
      alert("Sign Up form cannot have empty values")
    } else {
      try {
        const response = await fetch("https://jeeta-backend.fly.dev/api/user/sign_up", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, email, password }),
        });

        if (response.ok) {
          const data = await response.json();
          const token = data.jwt;
          const userId = data.userId.S;

          if (rememberMe) {
            localStorage.setItem("rememberedEmail", email);
            localStorage.setItem("rememberedPassword", password);
          } else {
            localStorage.removeItem("rememberedEmail");
            localStorage.removeItem("rememberedPassword");
          }

          localStorage.setItem("jwtToken", token);
          localStorage.setItem("userId", userId);
          setIsAuthenticated(true);
          navigate("/membership");
        } else {
          console.error("Sign up failed!");
        }
      } catch (error) {
        console.error("Error during sign up:", error);
      }
    }

  };
  return (
    <section id="sign-up">
      <div className="sign-up-center">
        <div className="sign-up-text">
          <h3 className="sign-up-text-header">welcome</h3>
          <p className="sign-up-text-action">Please create an account</p>
          <GoogleLogin
            onSuccess={responseMessage}
            onError={errorMessage}
          >
          </GoogleLogin>

          <p className="sign-up-text-line">or</p>

          <div className="sign-up-text-input">
            <input
              type="text"
              required
              placeholder="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="sign-up-text-input">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="email"
            />
          </div>
          <div className="sign-up-text-input">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="password"
            />
          </div>

          <div className="sign-up-text-savePassword">
            <div className="sign-up-text-savePassword-check">
              <input
                id="rememberCheckbox"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
              />
              <p>Remember me for 30 days</p>
            </div>
            <div>
              <Link to="/resetPasswordRequest">
                <p className="sign-up-text-savePassword-forgetPass">
                  Forgot Password
                </p>
              </Link>
            </div>
          </div>

          <button className="sign-up-text-btn" onClick={handleSignUp}>
            {" "}
            Sign Up
          </button>

          <div className="sign-up-text_signUp">
            <p>Already have an account?</p>
            <Link to="/login">Sign In</Link>
          </div>
        </div>

        <div className="sign-up-img"></div>
      </div>
    </section>
  );
};
