import React, { useState } from "react";
import "./Membership.css";
import { useNavigate } from "react-router-dom";
import GradientLine from "../../components/GradientLine/GradientLine";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Footer from "../../components/Footer/Footer";

export const Membership = () => {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    // Create an object to hold the form values
    const formValues = {};

    // Loop through the formData and populate the formValues object
    for (const [name, value] of formData.entries()) {
        formValues[name] = value;
    }

    console.log(formValues);

    const userId = localStorage.getItem("userId");
    const jwt = localStorage.getItem("jwtToken");

    if (!jwt) {
      console.error("JWT not found in localStorage");
      return;
    }

    const url = `https://jeeta-backend.fly.dev/api/user/${userId}/createApplication`;

    try {
      const response = await fetch(url, {
        method: "PUT",
        headers: {
          Authorization: jwt,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });

      if (response.ok) {
        console.log("Application submission success");

        const data = await response.json();
        const { s3Link } = data;

        if (formData.get("photo")) {
          try {
            const imageBlob = formData.get("photo");
            const imageResponse = await fetch(s3Link, {
              method: "PUT",
              headers: {
                Authorization: jwt,
                "Content-Type": "image/jpeg", // Adjust content type if needed
              },
              body: new Blob(imageBlob),
            });

            if (imageResponse.ok) {
              console.log("Image upload success");
              navigate("/membershipSuccess");
            } else {
              console.error("Image upload failed");
            }
          } catch (error) {
            console.error("Error uploading image:", error);
          }
        } else {
          navigate("/membershipSuccess");
        }
      } else {
        console.error("Application update submission failed");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <section id="membership">
      <div className="membership-showcase">
        <h4>Membership</h4>
        <p className="para-one">Take the First Step, Find Your Soul Mate</p>
        <GradientLine />
        <p className="para-two">
          Join a community of countless matched couples today!
        </p>

        <div className="showcase-chevron">
          <span>Complete The Registration Form</span>
          <p>
            <HiOutlineChevronDoubleDown />
          </p>
        </div>
      </div>

      <div className="membership-details">
        <h5>Apply to become a Member</h5>
        <p className="para-details-one">
          We typically respond within 1-2 days to arrange a consultation. Since
          we receive many applications, we are unable to respond to everyone. We
          can only respond to those we choose to move forward with. If we choose
          to move forward with your application.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="membership-form">
        <h4 className="form-header">Please fill the form below</h4>
        <div className="input-container-box-1">
          <div className="input-box">
            <label htmlFor="firstName">enter first name</label>
            <input type="text" name="firstName" id="firstName" required />
          </div>
          <div className="input-box">
            <label htmlFor="lastName">enter last name</label>
            <input type="text" name="lastName" id="lastName" required />
          </div>
        </div>
        <div className="input-container-box-1">
          <div className="input-box">
            <label htmlFor="emailAddress">enter email address</label>
            <input
              type="email"
              name="emailAddress"
              id="emailAddress"
              required
            />
          </div>
          <div className="input-box">
            <label htmlFor="mobileNumber">enter mobile number</label>
            <input
              type="number"
              name="mobileNumber"
              id="mobileNumber"
              required
            />
          </div>
        </div>
        <div className="input-container-box-1">
          <div className="input-box">
            <label htmlFor="country">enter country name</label>
            <input type="text" name="country" id="country" required />
          </div>
          <div className="input-box">
            <label htmlFor="city">enter city name</label>
            <input type="text" name="city" id="city" required />
          </div>
        </div>
        <div className="input-container-box-1">
          <div className="input-box">
            <label htmlFor="address">Enter your address</label>
            <input type="text" name="zipCode" id="zipCode" required />
          </div>
          <div className="input-box">
            <label htmlFor="height">enter height (cm)</label>
            <input type="number" name="height" id="height" required />
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="gender">gender</label>
          <div className="input-box">
            <input type="radio" name="gender" id="gender" required />
            <span>male</span>
          </div>
          <div className="input-box">
            <input type="radio" name="gender" id="gender" required />
            <span>female</span>
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="dateOfBirth">date of birth</label>
          <div className="input-box">
            <input type="date" name="dateOfBirth" id="dateOfBirth" />
          </div>
        </div>
        <div className="input-container-box-3">
          <label htmlFor="ethnicity">ethnicity</label>
          <input type="text" name="ethnicity" id="ethnicity" />
        </div>
        <div className="input-container-box-2">
          <label htmlFor="religion">do you practice religion?</label>
          <div className="input-box">
            <input type="radio" name="religion" id="religion" required />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input type="radio" name="religion" id="religion" required />
            <span>no</span>
          </div>
        </div>
        <div className="input-container-box-3">
          <label htmlFor="religionName">what is your religion?</label>
          <input type="text" name="religionName" id="religionName" />
        </div>
        <div className="input-container-box-2">
          <label htmlFor="maritalStatus">marital status</label>
          <div className="input-box">
            <input
              type="radio"
              name="maritalStatus"
              id="maritalStatus"
              required
            />
            <span>married</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="maritalStatus"
              id="maritalStatus"
              required
            />
            <span>single</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="maritalStatus"
              id="maritalStatus"
              required
            />
            <span>divorced</span>
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="hasChildren">do you have children?</label>
          <div className="input-box">
            <input type="radio" name="hasChildren" id="hasChildren" required />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input type="radio" name="hasChildren" id="hasChildren" required />
            <span>no</span>
          </div>
        </div>
        <div className="input-container-box-2">
          <label htmlFor="wantChildren">do you want to have children?</label>
          <div className="input-box">
            <input
              type="radio"
              name="wantChildren"
              id="wantChildren"
              required
            />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="wantChildren"
              id="wantChildren"
              required
            />
            <span>no</span>
          </div>
        </div>

        <div className="input-container-box-3">
          <label htmlFor="highestQualification">
            Please enter the highest level of education obtained
          </label>
          <input
            type="text"
            name="highestQualification"
            id="highestQualification"
          />
        </div>

        <div className="input-container-box-3">
          <label htmlFor="universityDegreeSubject">
            please enter your course-major studied
          </label>
          <input
            type="text"
            name="universityDegreeSubject"
            id="universityDegreeSubject"
          />
        </div>

        <div className="input-container-box-3">
          <label htmlFor="profession">
            Please enter your occupation/profession
          </label>
          <input type="text" name="profession" id="profession" />
        </div>

        <div className="input-container-box-1 income">
          <div className="input-box">
            <label htmlFor="annualIncome">
              Please enter your annual income (£)
            </label>
            <input
              type="number"
              name="annualIncome"
              id="annualIncome"
              required
            />
          </div>

          <div className="input-box">
            <label htmlFor="netWorth">enter net worth (£)</label>
            <input type="number" name="netWorth" id="netWorth" required />
          </div>
        </div>

        <div className="input-container-box-4">
          <label htmlFor="howDidYouLearnAboutUs">
            how did you hear about jeeta matchmaking
          </label>
          <textarea
            name="howDidYouLearnAboutUs"
            id="howDidYouLearnAboutUs"
          ></textarea>
        </div>

        <div className="input-container-box-3">
          <label htmlFor="photo">Upload a picture of yourself (current selfie)</label>
          <input type="file" name="photo" accept="image/jpeg, image/png" required />
        </div>

        <div className="form-contact">
          <h4>contact preferences</h4>
          <p>
            Following successful review of your application we will contact you
            to arrange a suitable time for your interview so we can get to know
            you better.
          </p>
        </div>

        <div className="input-container-box-2">
          <label htmlFor="contactPreference">
            How would you like to be contacted to arrange a consultation
          </label>
          <div className="input-box">
            <input
              type="radio"
              name="contactPreference"
              id="contactPreference"
              required
            />
            <span>whatsapp messenger</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="contactPreference"
              id="contactPreference"
              required
            />
            <span>Email</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="contactPreference"
              id="contactPreference"
              required
            />
            <span>telephone</span>
          </div>
        </div>

        <div className="form-contact">
          <h4>contact preferences</h4>
          <p>
            Following successful review of your application we will contact you
            to arrange a suitable time for your interview so we can get to know
            you better.
          </p>
        </div>

        <div className="input-container-box-2">
          <label htmlFor="consultationPreference">
            How would you prefer to conduct your matchmaking consultation
          </label>
          <div className="input-box">
            <input
              type="radio"
              name="consultationPreference"
              id="consultationPreference"
              required
            />
            <span>Video Call</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="consultationPreference"
              id="consultationPreference"
              required
            />
            <span>telephone</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="consultationPreference"
              id="consultationPreference"
              required
            />
            <span>whatsapp messenger</span>
          </div>
          <div className="input-box">
            <input
              type="radio"
              name="consultationPreference"
              id="consultationPreference"
              required
            />
            <span>in person</span>
          </div>
        </div>

        <div className="input-container-box-2">
          <label htmlFor="consent">
            Do you consent to Jeeta Matchmaking Permission to handle your data
            (see <a href="/privacyPolicy">Privacy Policy</a> for more info)
          </label>
          <div className="input-box">
            <input type="radio" name="consent" id="consent" required />
            <span>yes</span>
          </div>
          <div className="input-box">
            <input type="radio" name="consent" id="consent" required />
            <span>no</span>
          </div>
        </div>

        <button className="form-btn">submit your application</button>
      </form>

      <Footer />
    </section>
  );
};
