import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import "./Navbar.css";

const Navbar = () => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear the authentication token from local storage
    localStorage.removeItem("jwtToken");

    // Set the isAuthenticated state to false
    setIsAuthenticated(false);

    // Redirect to the desired page (e.g., login page)
    navigate("/login");
  };

  // Add background to the navbar when the windows height is scrolled
  useEffect(() => {
    const navbar = document.querySelector("#nav");
    window.addEventListener("scroll", () => {
      if (window.pageYOffset + window.innerHeight >= navbar.offsetHeight / 2) {
        navbar.classList.add("bg");
      }
    });
    return window.removeEventListener("scroll", () => {});
  }, []);

  return (
    <nav id="nav">
      <ul className="navlinks">
        <li>
          <Link to="/aboutUs" className="link">
            About us
          </Link>
        </li>
        {isAuthenticated ? (
          <>
            <li>
              <Link to="/membership" className="link">
                Membership
              </Link>
            </li>
            <li>
              <Link to="/amendMembership" className="link">
                Your profile
              </Link>
            </li>
            <li>
              <button className="signOut-btn" onClick={handleSignOut}>
                <Link>Sign out</Link>
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <button className="login-btn">
                <Link to="/login">Login</Link>
              </button>
            </li>
            <li>
              <button className="signUp-btn">
                <Link to="/signup">Sign up</Link>
              </button>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
