import React from "react";
// import { Video } from "../../components/Video";
import "./AboutUs.css";
import GradientLine from "../../components/GradientLine/GradientLine";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import Person1 from "../../images/aboutUs-person-img-1.png";
import Person2 from "../../images/aboutUs-person-img-2.png";
import Footer from "../../components/Footer/Footer";

export const AboutUs = () => {
  return (
    <section id="about-us">
      <div className="about-us-showcase">
        <h4>About Us</h4>
        <p className="para-one">Jeeta Matchmaking - Who We Are</p>
        <GradientLine />
        <p className="para-two">
          Over 40 years of matchmaking experience and a community service rooted
          in family tradition.
        </p>

        <div className="showcase-chevron">
          <span>Discover Jeeta Matchmaking</span>
          <p>
            <HiOutlineChevronDoubleDown />
          </p>
        </div>
      </div>

      <div className="about-us-honour">
        <div className="about-us-honour-text">
          <h4>In honour of Jetalal Behal</h4>
          <p className="para-one">
            Despite being a boutique matchmaking service based in London. We
            have very humble beginnings.
          </p>
          <p className="para-two">
            Mr Jetalal Bhai, now a 79 year old man, matched hundreds of now
            married men and women in his retirement, astonishingly all from a
            small shed in West London.
          </p>
        </div>
        <div className="about-us-honour-img">
          <img src={Person1} alt="about-person-1" />
        </div>
      </div>

      <div className="about-us-how">
        <h4>how we do it?</h4>
        <p>
          We have spent years building an extensive network for connecting high
          quality singles across the country. Most come to us via word-of-mouth,
          while others are referrals from similar organisations. This adds an
          extra layer to the pre-screening process. Increasing our ability to
          match you with the perfect partner.
        </p>
      </div>

      <div className="about-us-honour">
        <div className="about-us-honour-text">
          <h4>nikhil hindocha</h4>
          <p className="para-one">Director & Founder, Jeeta Matchmaking.</p>
          <p className="para-two">
            After years of unfaltering success in the field of matchmaking, the
            grandson of Jetalal Behal, founded Jeeta Matchmaking to carry on the
            family tradition and contribute to countless more matrimonies across
            the country.
          </p>
        </div>
        <div className="about-us-honour-img">
          <img src={Person2} alt="about-person-2" />
        </div>
      </div>

      <Footer />
    </section>
  );
};
