import React from 'react'
import Navbar from './Navbar';
import { useLocation } from "react-router-dom";

const NavbarWithConditionalRendering = () => {
  const location = useLocation();
  const hideNavbarRoutes = ["/login", "/signup", "/resetPasswordRequest", '/reset-password'];

  // Check if the current location matches any of the routes where we want to hide the navbar
  const shouldHideNavbar = hideNavbarRoutes.some(
    (route) => location.pathname === route
  );

  return !shouldHideNavbar ? <Navbar /> : null;
}

export default NavbarWithConditionalRendering