import React from "react";
import { HiOutlineChevronDoubleDown } from "react-icons/hi";
import { BiNotepad } from "react-icons/bi";
import { BsTelephone, BsSearch } from "react-icons/bs";
import { HiOutlineUsers } from "react-icons/hi";
import { FiMessageCircle } from "react-icons/fi";
import Footer from "../../components/Footer/Footer";

import "./LandingPage.css";
import Step1Img from "../../images/step1.png";
import Step2Img from "../../images/step2.png";
import Step3Img from "../../images/step3.png";
import Step4Img from "../../images/step4.png";
import Step5Img from "../../images/step5.png";
import GradientLine from "../../components/GradientLine/GradientLine";

export const LandingPage = () => {
  return (
    <section id="landing-page">
      <div className="landing-page-showcase">
        <div className="header">
          <p>jeeta</p>
          <p>matchmaking</p>
        </div>
        <p className="para-one">
          Professional Matchmaking Service for South Asian Singles.
        </p>

        <GradientLine />

        <button className="border-gradient border-gradient-color">
          become a free member
        </button>
        <p className="para-two">
          Join hundreds of successfully matched couples and take the first step
          to future matrimony.
        </p>

        <div className="showcase-chevron">
          <span>how it works</span>
          <p>
            <HiOutlineChevronDoubleDown />
          </p>
        </div>
      </div>

      <div className="landing-page-details">
        <div className="header">
          <h3>Free Membership Process</h3>
          <p>
            The five step process that has made hundreds of South Asian
            families.
          </p>
        </div>

        <div className="landing-page-details_steps">
          <article className="landing-page-details_steps-box">
            <div className="landing-page-details_img-box">
              <img src={Step1Img} alt="step1-img" />
              <button className="landing-page-details_img-box-icon">
                <BiNotepad />
                <p className="landing-page-details_img-box-icon_bg"></p>
              </button>
            </div>
            <div className="landing-page-details_steps-box-texts">
              <h4>step 1</h4>
              <h6>Complete the registration form</h6>
              <p>
                You’ll do this after signing up. This is so we can learn about
                you and what you’re looking for in a partnership.
              </p>
            </div>
          </article>
          <article className="landing-page-details_steps-box reverse">
            <div className="landing-page-details_img-box">
              <img src={Step2Img} alt="step2-img" />
              <button className="landing-page-details_img-box-icon">
                <BsTelephone />
                <p className="landing-page-details_img-box-icon_bg"></p>
              </button>
            </div>
            <div className="landing-page-details_steps-box-texts">
              <h4>step 2</h4>
              <h6>Complete the Interview</h6>
              <p>
                If your a good fit we'll extend an invitation to interview with
                one of our matchmakers. This is usually an hour.
              </p>
            </div>
          </article>
          <article className="landing-page-details_steps-box">
            <div className="landing-page-details_img-box">
              <img src={Step3Img} alt="step3-img" />
              <button className="landing-page-details_img-box-icon">
                <BsSearch />
                <p className="landing-page-details_img-box-icon_bg"></p>
              </button>
            </div>
            <div className="landing-page-details_steps-box-texts">
              <h4>step 3</h4>
              <h6>We find your matches</h6>
              <p>
                We'll conduct an in-depth, customized search to find matches
                that align with what we discussed in your interview.
              </p>
            </div>
          </article>
          <article className="landing-page-details_steps-box reverse">
            <div className="landing-page-details_img-box">
              <img src={Step4Img} alt="step4-img" />
              <button className="landing-page-details_img-box-icon">
                <HiOutlineUsers />
                <p className="landing-page-details_img-box-icon_bg"></p>
              </button>
            </div>
            <div className="landing-page-details_steps-box-texts">
              <h4>step 4</h4>
              <h6>Meet your matches</h6>
              <p>
                We schedule your dates at some of the best restaurants in London
                to ensure you have a great experience.
              </p>
            </div>
          </article>
          <article className="landing-page-details_steps-box">
            <div className="landing-page-details_img-box">
              <img src={Step5Img} alt="step5-img" />
              <button className="landing-page-details_img-box-icon">
                <FiMessageCircle />
                <p className="landing-page-details_img-box-icon_bg"></p>
              </button>
            </div>
            <div className="landing-page-details_steps-box-texts">
              <h4>step 5</h4>
              <h6>Feedback (Report, Reset, Repeat)</h6>
              <p>
                We evaluate all of your matches together. What did you like?
                What would you like us to focus on for your next match?
              </p>
            </div>
          </article>
        </div>
      </div>

      <div className="no-bg-btn-container">
        <button className="border-gradient border-gradient-color no-bg">
          start your application
        </button>
      </div>

      <Footer />
    </section>
  );
};
