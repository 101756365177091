import { Link } from "react-router-dom";
import "./SubmitSuccessful.css";

const SubmitSuccessful = () => {
  return (
    <div id="submit-container-bg">
      <article className="submit-success">
        <p className="submit-success-para-one">Thank you for submitting your email!</p>
        <p className="submit-success-para-two">
          If the email you entered is registered with us, check your email, you
          should receive the instructions shortly.
        </p>
        <button disabled="disabled">Submitted</button>

        <div className="submit-success-register">
          <span>don't have an account?</span>
          <Link to="/signup">
            <p>sign up</p>
          </Link>
        </div>

        <div className="submit-success-redirect">
          <span>Back To</span>
          <Link to="/">
            <p>homepage</p>
          </Link>
        </div>
      </article>
    </div>
  );
};

export default SubmitSuccessful;
