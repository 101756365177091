import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthContext } from "./AuthContext";

const PrivateRoute = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  if (token) {
    return children;
  } else if (isAuthenticated) {
    return children;
  } else {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
